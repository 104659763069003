import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const initialState = {
    usersList: null,  // The initial state for the users list
    loading: false,    // To track the loading state
    error: null        // To track any errors
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers(state, action) {
            state.usersList = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Persist configuration specific to this slice
const persistConfig = {
    key: 'users', // Key for storage
    storage,
    // You can whitelist/blacklist certain parts of the state if needed
    // whitelist: ['usersList'], // Persist only usersList
    // blacklist: ['loading', 'error'], // Do not persist loading and error states
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, usersSlice.reducer);

// Export the actions to be used in components
export const { setUsers, setLoading, setError } = usersSlice.actions;

// Export the persisted reducer to be included in the store
export default persistedReducer;
