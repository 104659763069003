import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
  appSomethingIsLoading,
  appAllLoaded,
  appSetSearchOfficesOnly,
} from '../actions';

import mongoConfig from "../mongo";

const useDropdownOffices = (selectedOfficeStatus, selectedGroup) => {
  const dispatch = useDispatch();
  const mongoUser = useSelector( (state) => state.auth.user );
  const [officesList, setOfficesList] = useState(null);

  useEffect(() => {
    {
      dispatch(appSomethingIsLoading());

      let officesFilter = {};
      if (selectedGroup) {
        officesFilter['groupID'] = selectedGroup;
      }
      if ( selectedOfficeStatus && selectedOfficeStatus !== 'all' ) {
        officesFilter['officeStatus'] = selectedOfficeStatus;
      }
      mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('offices')
      .find(officesFilter,
            {sort: { 'name': 1}, projection: { _id: 1, name: 1}}
          )
      .then(offices => {
        dispatch(appAllLoaded());
        let renamedOffices = offices.map((e) => ( { id: e._id, label: e.name }) );
        let officesIDs = offices.map((e) => ( e._id) );

        if ( selectedOfficeStatus && selectedOfficeStatus !== 'all' ) {
          dispatch(appSetSearchOfficesOnly(officesIDs));
        } else {
          dispatch(appSetSearchOfficesOnly(null));
        }
        setOfficesList(renamedOffices);
      });
    }

  }, [dispatch, mongoUser, selectedGroup, selectedOfficeStatus]);
  return officesList;
}

export default useDropdownOffices;
