import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {MenuItem, FormControl, InputLabel, Select, Box, Typography} from '@mui/material';

import theApp from '../../config/app';
import mongoConfig from '../../mongo';

import { doRefreshCustomData } from '../../utils';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  bluredItem: (props) => {
    if ( !!props.demo && props.demo === true ) {
      return {
        filter: 'blur(3px)',
      };
    }
  },
  compSelectContainer: {
    width: '100%',
    '& .MuiSelect-outlined': {
      paddingTop:'0.8em',
      paddingBottom:'0.8em',
    }
  }
}));


const CompanySelector = () => {
  const dispatch = useDispatch();
  const mongoUser = useSelector( (state) => state.auth.user );

  const styleProps = ( !!mongoUser.customData.demo && Boolean(mongoUser.customData.demo) === true ) ? {demo: true} : {};
  const classes = useStyles(styleProps);

  const [currentCompany, setCurrentCompany] = useState(mongoUser.customData.company || undefined);

  const handleCompChange = (event) => {
    // dispatch(appSomethingIsLoading());

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('usersdata')
      .updateOne(
        { userID: mongoUser.id},
        { $set: {'company': event.target.value} }
      ).then(() => {
        setCurrentCompany(event.target.value);
        dispatch(doRefreshCustomData());
      }).catch((err) => {
        console.log('Company update error: ' + err);
      });
  };

  return (
    <Box className={classes.compSelectContainer}>
      { ( !!mongoUser.customData && !! mongoUser.customData.role && (mongoUser.customData.role === 'admin' ) )
          && <FormControl variant="outlined" size="small" className={classes.compSelectContainer}>
              <InputLabel id="demo-simple-select-outlined-label">Company</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                disabled={ (currentCompany === undefined || !currentCompany) ? true : false }
                value={ ( currentCompany !== undefined ) ? currentCompany : '' }
                onChange={handleCompChange}
                label="Company"
                autoWidth
                className={classes.bluredItem}
              >
                {
                  theApp.companies.map((oneCompany) => {
                      return (
                        <MenuItem className={classes.bluredItem} key={oneCompany.code} value={oneCompany.code}>{oneCompany.name}</MenuItem>
                      );
                  })
                }
              </Select>
            </FormControl>
      }
      { ( currentCompany && !!mongoUser.customData && !! mongoUser.customData.role && (mongoUser.customData.role === 'companyadmin' || mongoUser.customData.role === 'callreviewer'))
          && <Typography>Company: { theApp.companies.find(c => c.code === currentCompany).name }</Typography>
      }
    </Box>
  );
}

export default CompanySelector;
