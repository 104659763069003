export const pagesConstants = {
  PAGE_DASHBOARD: "PAGE_DASHBOARD",
  PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  PAGE_ACCESS_DENIED: "PAGE_ACCESS_DENIED",
  PAGE_LOGIN: "PAGE_LOGIN",
  PAGE_CONFIRM_EMAIL: "PAGE_CONFIRM_EMAIL",
  PAGE_EMAILED_INVITATION: "PAGE_EMAILED_INVITATION",
  PAGE_GOOGLE_AUTH: "PAGE_GOOGLE_AUTH",
  PAGE_AZURE_AUTH: "PAGE_AZURE_AUTH",
  PAGE_SUBTOTAL_ASSISTS: "PAGE_SUBTOTAL_ASSISTS",
  PAGE_CALLS_ALL: "PAGE_CALLS_ALL",
  PAGE_CALLS_ANSWERED: "PAGE_CALLS_ANSWERED",
  PAGE_CALLS_BOOKED: "PAGE_CALLS_BOOKED",
  PAGE_CALLS_NOTBOOKED: "PAGE_CALLS_NOTBOOKED",
  PAGE_CALLS_MISSED: "PAGE_CALLS_MISSED",
  PAGE_CALLS_BUSY: "PAGE_CALLS_BUSY",
  PAGE_CALLS_NOANSWER: "PAGE_CALLS_NOANSWER",
  PAGE_CALLS_ISSPAM: "PAGE_CALLS_ISSPAM",
  PAGE_CALLS_HANGUP: "PAGE_CALLS_HANGUP",
  PAGE_CALLS_VOICEMAIL: "PAGE_CALLS_VOICEMAIL",
  PAGE_CALLS_UNKNOWN: "PAGE_CALLS_UNKNOWN",
  PAGE_CALLS_OTHER: "PAGE_CALLS_OTHER",
  PAGE_CALLS_TAGGED: "PAGE_CALLS_TAGGED",
  PAGE_GROUPS: "PAGE_GROUPS",
  PAGE_USERS: "PAGE_USERS",
  PAGE_OFFICES: "PAGE_OFFICES",
  PAGE_CALLVIEWS: "PAGE_CALLVIEWS",
  PAGE_ALERTSALLDATA: "PAGE_ALERTSALLDATA",
  PAGE_ALERTSVOLUME: "PAGE_ALERTSVOLUME",
  PAGE_APPTCALLS: "PAGE_APPTCALLS",
  PAGE_APPTCALLSDROP: "PAGE_APPTCALLSDROP",
  PAGE_ALERTSMISSED: "PAGE_ALERTSMISSED",
  PAGE_ALERTSOTHER: "PAGE_ALERTSOTHER",
  PAGE_ALERTSCONVERSIONS: "PAGE_ALERTSCONVERSIONS",
  PAGE_REVIEWCALL: "PAGE_REVIEWCALL",
  PAGE_ACCOUNTSETTINGS: "PAGE_ACCOUNTSETTINGS",
};
