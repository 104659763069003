import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const keywordsSlice = createSlice({
  name: 'keywords',
  initialState: {
    parentKeywords: [],
    totalCount: 0,
  },
  reducers: {
    setParentKeywords(state, action) {
      state.parentKeywords = action.payload;
    },
    setTotalCount(state, action) {
      state.totalCount = action.payload;
    },
  },
});

const persistConfig = {
  key: 'keywords',
  storage,
};

export const { setParentKeywords, setTotalCount } = keywordsSlice.actions;
export default persistReducer(persistConfig, keywordsSlice.reducer);