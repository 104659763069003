import * as Realm from "realm-web";

import mongoConfig from "../mongo";

import {
  dialogInProgress,
  dialogSuccess,
  dialogError,
  authInProgress,
  authRefresh,
  authSuccess,
  authFail,
  authMessage,
  appSomethingIsLoading,
  appAllLoaded
} from '../actions';


export const doVerifyAuth = (user) => dispatch => {
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  if ( !user || !user.isLoggedIn ) {
    if ( !!currentApp.currentUser && currentApp.currentUser !== null) {
      dispatch(authSuccess(currentApp.currentUser));
    } else {
      dispatch(authFail());
    }
  }
};

export const doSetNewPassword = (newPassword, token, tokenId) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.resetPassword(token, tokenId, newPassword).then(() => {
    dispatch(authMessage('Updated. You can login now with your new password.'));
  }, reason => {
    dispatch(authFail({message: 'Setting new password failed: '+reason.error}));
  });
};

export const doIssuePasswordReset = (email) => (dispatch) => {
  dispatch(appSomethingIsLoading());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  currentApp.emailPasswordAuth.sendResetPasswordEmail(email).then(
    () => {
      console.log('emailed');
      dispatch(appAllLoaded());
    }, (error) => {
      console.log('error');
      console.log(error);
    }
  );
};

export const doResetPasswordEmail = (email) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.sendResetPasswordEmail(email).then(() => {
    dispatch(authMessage('Password reset instructions was emailed to you.'));
  });
};

export const doAzureHandleToken = (token) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  const credentials = Realm.Credentials.jwt(token);

  currentApp.logIn(credentials).then(user => {

    if ( user.id === currentApp.currentUser.id ) {
      if ( !user.customData.role ) {
        // user logs in first time with a google
        // no record is created in database for him

        if ( user.profile && user.profile.name ) {
          console.log('update profile');
          // update userID in usersdata collection
          user
            .mongoClient(mongoConfig.realmServiceName)
            .db(mongoConfig.databaseName)
            .collection('usersdata')
            .updateOne(
              { username: user.profile.name },
              { $set: {'userID': user.id } }
            ).then(() => {

              currentApp.currentUser.refreshCustomData().then( () => {
                if ( !currentApp.currentUser.customData.role ) {
                  currentApp.currentUser.logOut().then(() => {
                    dispatch(authFail({message: 'No user associated with your Azure account.'}));
                  });
                } else {
                  dispatch(authSuccess(currentApp.currentUser));
                }
              });

            }).catch((err) => {
              console.log('UserID update error: ' + err);
              currentApp.currentUser.logOut().then(() => {
                dispatch(authFail({message: 'UserID update error.'}));
              });
            });

        } else {
          console.log('before logout');
          currentApp.currentUser.logOut().then(() => {
            dispatch(authFail({message: 'Azure did not grant access to email address.'}));
          });
        }

      } else {
        // normal user
        console.log('normal login');
        dispatch(authSuccess(user));
      }
    }
    else dispatch(authFail({message: 'User ID does not match Application User ID.'}));
  }, reason => {
    console.log(reason);
    dispatch(authFail({message: 'User with such email and/or password - not found.'}));
  });
}

export const doGoogleHandleRedirect = () => {
  console.log('handle redirect');
  Realm.handleAuthRedirect();
}

export const doGoogleLogin = () => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  const credentials = Realm.Credentials.google({ authCode: 'https://www.ringanalytics.ai/googleauth' });

  currentApp.logIn(credentials).then(user => {
    if ( user.id === currentApp.currentUser.id ) {

      if ( !user.customData.role ) {
        // user logs in first time with a google

        if ( user.profile && user.profile.email ) {
          // console.log(user.profile.email);
          // update userID in usersdata collection
          user
            .mongoClient(mongoConfig.realmServiceName)
            .db(mongoConfig.databaseName)
            .collection('usersdata')
            .updateOne(
              { username: user.profile.email },
              { $set: {'userID': user.id} }
            ).then(() => {

              currentApp.currentUser.refreshCustomData().then( () => {
                if ( !currentApp.currentUser.customData.role ) {
                  currentApp.currentUser.logOut().then(() => {
                    dispatch(authFail({message: 'No user associated with your Google account.'}));
                  });
                } else {
                  dispatch(authSuccess(currentApp.currentUser));
                }
              });

            }).catch((err) => {
              console.log('UserID update error: ' + err);
              currentApp.currentUser.logOut().then(() => {
                dispatch(authFail({message: 'UserID update error.'}));
              });
            });

        } else {
          currentApp.currentUser.logOut().then(() => {
            dispatch(authFail({message: 'Google did not grant access to email address.'}));
          });
        }

      } else {
        // normal user
        console.log('normal login');
        dispatch(authSuccess(user));
      }
    }
    else dispatch(authFail({message: 'User ID does not match Application User ID.'}));
  }, reason => {
    console.log(reason);
    dispatch(authFail({message: 'User with such email and/or password - not found.'}));
  });

};

export const doLoginUser = (email, password) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  const credentials = Realm.Credentials.emailPassword(email, password);

  currentApp.logIn(credentials).then(user => {
    if ( user.id === currentApp.currentUser.id ) {
      if ( !user.customData.role ) {
        // first time login user
        // set userID in usersdata collection to user.id to link user.id with user
        user
          .mongoClient(mongoConfig.realmServiceName)
          .db(mongoConfig.databaseName)
          .collection('usersdata')
          .updateOne(
            { username: email },
            { $set: {'userID': user.id} }
          ).then(() => {
            // console.log('updated');
            currentApp.currentUser.refreshCustomData().then( () => {
              if ( !currentApp.currentUser.customData.role ) {
                currentApp.currentUser.logOut().then(() => {
                  dispatch(authFail({message: 'No user associated with your email address.'}));
                });
              } else {
                dispatch(authSuccess(currentApp.currentUser));
              }
            });
          }).catch((err) => {
            console.log('UserID update error: ' + err);
            currentApp.currentUser.logOut().then(() => {
              dispatch(authFail({message: 'UserID update error.'}));
            });
          });
      } else {
        // normal user
        console.log('normal login');
        dispatch(authSuccess(user));
      }
    }
    else dispatch(authFail({message: 'User ID does not match Application User ID.'}));
  }, reason => {
    console.log(reason);
    dispatch(authFail({message: 'User with such email and/or password - not found.'}));
  });
};

export const doLogoutUser = () => () => {

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.currentUser.logOut().then(() => {
    window.location.reload();
  });
};

export const doRefreshCustomData = () => (dispatch) => {
  dispatch(authRefresh());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.currentUser.refreshCustomData().then( () => {
    dispatch(authSuccess(currentApp.currentUser));
  });
};

export const doRemoveUser = (userToBeDeleted) => () => {
  console.log('do remove');
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  console.log(currentApp.currentUser);
  console.log(userToBeDeleted);

  currentApp.removeUser(userToBeDeleted).then( () => {
    // dispatch(dialogSuccess());
    console.log('after delete');
  }, reason => {
    // dispatch(dialogError(reason.error));
    console.log(reason);
  });

};

export const doCreateUser = (userDetails) => (dispatch) => {
  dispatch(dialogInProgress());
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.registerUser(userDetails).then( () => {
    dispatch(dialogSuccess());
  }, reason => {
    dispatch(dialogError(reason.error));
  });

};

export const doEmailUserInvitation = (mongoUser, userDetails) => (dispatch) => {
  dispatch(dialogInProgress());

  mongoUser.callFunction("SendMailUserInvitation", userDetails)
    .then( (inviteSentResults) => {
      console.log(inviteSentResults);
      dispatch(dialogSuccess());
    }).catch((err) => {
      console.log('invitation email error: ' + err);
      dispatch(dialogError(err));
    });
};


export const doResendConfirmUserEmail = (email) => (dispatch) => {
  dispatch(appSomethingIsLoading());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.resendConfirmationEmail({email: email}).then(
    () => {
      console.log('emailed');
      dispatch(appAllLoaded());
    }, reason => {
      console.log(reason);
      dispatch(appAllLoaded());
    }
  );
}

export const doConfirmUserEmail = (token, tokenId) => {

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  if ( currentApp.currentUser ) {
    return currentApp.currentUser.logOut().then(() => {
      return currentApp.emailPasswordAuth.confirmUser({token: token, tokenId: tokenId}).then( () => {
        return true;
      }, reason => {
        console.log(reason);
        return false;
      });
    });
  } else {
    return currentApp.emailPasswordAuth.confirmUser({token: token, tokenId: tokenId}).then( () => {
      return true;
    }, reason => {
      console.log(reason);
      return false;
    });
  }

};
