import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from '../reducers';

// Persist configuration object
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [], // only persist these
  // blacklist: ['someOtherReducer'], // do not persist someOtherReducer
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore(preloadedState) {
  // Create store with persisted reducer using configureStore
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // You might need this if you persist non-serializable values
        immutableCheck: false, // You might need this if you persist immutable values
      }),
  });

  // Create a persistor
  const persistor = persistStore(store);

  return { store, persistor };
}
