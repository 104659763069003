import React from 'react';

import { Container, Box } from '@mui/material';

import { doGoogleHandleRedirect } from "../utils";

export const GoogleAuthPage = () => {

  doGoogleHandleRedirect();

  return (
    <Container maxWidth="xs">
      <Box display="flex" height={1} >
        <Box width={1} m="auto">
          Google Auth Page
        </Box>
      </Box>
    </Container>
  );
};
