import React, {useState, Fragment} from 'react';
import { useSelector } from "react-redux";
import { Fab } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import AdminNotesCard from './AdminNotesCard';
import OfficeAdminNotesCard from './OfficeAdminNotesCard';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  notesBubbleButton: {
    position: 'fixed',
    right: '1em',
    bottom: '1em',
    zIndex: 9999,
  }
}));

export const NotesBubble = ( ) => {
  const mongoUser = useSelector( (state) => state.auth.user );
  const classes = useStyles();

  const [isChatOpen, setIsChatOpen] = useState(false);

  // const [saveInProgress, setSaveInProgress] = useState(false);

  // const [officeAdminsForDropdown, setOfficeAdminsForDropdown] = useState([]);

  const handleCloseChat = () => {
    setIsChatOpen(false);
  }

  return (
    <Fragment>
      { (!isChatOpen && mongoUser && mongoUser.customData && mongoUser.customData.role === 'admin') &&
        <Fab
          color="success"
          variant="extended"
          className={classes.notesBubbleButton}
          onClick={() => setIsChatOpen(true)}
          >
            <EditIcon sx={{ mr: 1 }} />
            Office Notes
        </Fab>
      }
      { ( isChatOpen && mongoUser && mongoUser.customData && mongoUser.customData.role === 'admin') &&
        <AdminNotesCard closeChat={handleCloseChat} />
      }
      { ( mongoUser && mongoUser.customData && mongoUser.customData.role === 'officeadmin') &&
        <OfficeAdminNotesCard />
      }

    </Fragment>

  );
}

export default NotesBubble;
