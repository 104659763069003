import React, { useState } from 'react';

import { Grid, TextField, Button, Link } from '@mui/material';

export const ResetPasswordForm = (props) => {

  const { onResetSubmit, onStageChange } = props;

  const [email, setEmail] = useState('');

  const handleEmailChange = ({target}) => {
    setEmail(target.value);
  }

  const handleResetSubmit = (target) => {
    target.preventDefault();
    onResetSubmit(email);
  }

  const handleBackLoginClick = (target) => {
    target.preventDefault();
    onStageChange('login');
  }

  return (
    <form method="POST" onSubmit={handleResetSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField onChange={handleEmailChange} required fullWidth label="Email" name="email" size="small" variant="outlined" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button color="secondary" fullWidth type="submit" variant="contained">Reset Password</Button>
        </Grid>
        <Grid item justifyContent="center" alignContent="center" alignItems="center" container xs={12}>
          <Link href="#" onClick={handleBackLoginClick}>Back to Login Screen</Link>
        </Grid>
      </Grid>
    </form>
  );
};
