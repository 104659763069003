import React from 'react';

import { Box, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import StyledNavLink from '../Common/StyledNavLink';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    '& .nestedList': {
      '& .MuiListItemIcon-root': {
        marginLeft: '0.5em',
        marginRight: '-0.5em',
        opacity: '0.5',
      }
    },
    '& .menuIcon': {
      minWidth: '3em',
    },
    '& .menuLink': {
      color: theme.palette.sidebar.link,
    }
  },

}));

const CallReviewerMenu = () => {
  const classes = useStyles();

  return (
    <Box className={classes.menuContainer}>
        <List>
          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/review")} to="/calls/review">
            <ListItemIcon className="menuIcon"><AssignmentTurnedInIcon /></ListItemIcon>
            <ListItemText primary="Review a Call" />
          </ListItem>
        </List>
    </Box>
  );
}

export default CallReviewerMenu;
