import React, { Suspense }  from 'react';
import { Box } from '@mui/material';

// import CallsPageTemplate from '../../components/Calls/CallsPageTemplate';
const CallsPageTemplate = React.lazy(() => import('../../components/Calls/CallsPageTemplate'));
import PageTitle from '../../components/Common/PageTitle';

export const UnknownCallsPage = (props) => {

  return (
    <Box>
      <PageTitle thepage="/calls/unknown"/>
      <Suspense fallback={<span>...</span>}>
        <CallsPageTemplate
          props={props}
          callsSection='unknown'
          lineChartsHeading='Unknown Calls'
          heatmapColors='purples'
        />
      </Suspense>
    </Box>
  );
};
