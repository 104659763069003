import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledNavLink = styled(
  React.forwardRef(function StyledNavLink(props, ref) {
    const { button, ...rest } = props;
    return <RouterLink ref={ref} {...rest} />;
  })
)(({ theme }) => ({
  color: 'black', // Default link color
  textDecoration: 'none',
  '&:visited': {
    color: 'black', // Ensure visited links stay black
  },
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:active': {
    color: theme.palette.primary.main, // Active link color (optional)
  },
}));

export default StyledNavLink;