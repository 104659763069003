import { combineReducers } from 'redux';

import auth from './auth.reducer';
import db from './db.reducer';
import app from './app.reducer';
import groupsSlice from './features/groupsSlice';
import officesSlice from './features/officeSlice';
import usersSlice from './features/usersSlice';
import keywordsSlice from './features/keywordsSlice';

const rootReducer = combineReducers({
  groups: groupsSlice,
  offices: officesSlice,
  users: usersSlice,
  keywords: keywordsSlice,
  auth,
  db,
  app
});

export default rootReducer;
