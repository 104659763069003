import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Container, Box,  } from '@mui/material';

import { doAzureHandleToken } from "../utils";

export const AzureAuthPage = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [accessTokenValue, setAccessTokenValue] = useState(null);

  useEffect(() => {
    let accessToken = searchParams.get("access_token");
    if ( accessToken && accessToken !== '' ) {
      setAccessTokenValue(accessToken);
    }
  }, [searchParams]);

  useEffect(() => {
    if ( accessTokenValue !== null ) {
      dispatch(doAzureHandleToken(accessTokenValue));
    }
  }, [accessTokenValue]);
  //
  // searchParams.get("access_token")
  // dispatch(doAzureHandleToken());

  return (
    <Container maxWidth="xs">
      <Box display="flex" height={1} >
        <Box width={1} m="auto">
          Azure Auth in progress....
        </Box>
      </Box>
    </Container>
  );
};
