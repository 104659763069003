import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
// import moment from 'moment';
import moment from 'moment-timezone';
import {Box, Grid, Card, Stack, CardHeader, Typography, CardContent, FormControl, InputLabel, Select, MenuItem, Alert} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import PageTitle from '../../components/Common/PageTitle';
import UserDateTime from '../../components/Common/UserDateTime';

import mongoConfig from '../../mongo';
import { doRefreshCustomData } from '../../utils';

import theApp from '../../config/app';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  settingsContainer: {
    '& .enabledStatusDot': {
      paddingLeft: '1em',
    },
    '& .enabledStatusDot>span.MuiBadge-dot': {
      top: '12px',
      left: '-0.4em',
      right: 'auto',
      opacity: '0.6',
    }
  },
  formContainer: {

  }
}));



export const SettingsPage = () => {
  const dispatch = useDispatch();
  const mongoUser = useSelector( (state) => state.auth.user );
  const classes = useStyles();

  const [missedCallsAlerts, setMissedCallsAlerts] = useState(null);
  const [notBookedCallsAlerts, setNotBookedCallsAlerts] = useState(null);

  const [latestAlertsAggregation, setLatestAlertsAggregation] = useState(null);
  const [latestAlertsAggregationDiff, setLatestAlertsAggregationDiff] = useState(null);

  const [latestCallClassifiedDiff, setLatestCallClassifiedDiff] = useState(null);
  const [latestCallPreClassifiedDiff, setLatestPreCallClassifiedDiff] = useState(null);

  const [latestCallsArchiveRecord, setLatestCallsArchiveRecord] = useState(null);
  const [latestCallsArchiveRecordDaysDiff, setLatestCallsArchiveRecordDaysDiff] = useState(0);

  const [earliestCallsRecord, setEarliestCallsRecord] = useState(null);
  const [earliestCallsRecordDaysDiff, setEarliestCallsRecordDaysDiff] = useState(0);

  // const [latestDailyConversion, setLatestDailyConversion] = useState(null);
  // const [latestDailyConversionHoursDiff, setLatestDailyConversionHoursDiff] = useState(0);

  // const [latestMonthlyConversion, setLatestMonthlyConversion] = useState(null);
  // const [latestMonthlyConversionDaysDiff, setLatestMonthlyConversionDaysDiff] = useState(0);

  const [cachedLineCharts, setCachedLineCharts] = useState(null);
  const [cachedHeatMaps, setCachedHeatMaps] = useState(null);
  const [cachedDashboardCounters, setCachedDashboardCounters] = useState(null);
  const [cachedDashboardPieChart, setCachedDashboardPieChart] = useState(null);


  // get user missed calls alerts
  useEffect(() => {

    if ( !!mongoUser.customData.notifications && !!mongoUser.customData.notifications.missedCalls ) {
      setMissedCallsAlerts(mongoUser.customData.notifications.missedCalls);
    } else {
      setMissedCallsAlerts('none');
    }

    if ( !!mongoUser.customData.notifications && !!mongoUser.customData.notifications.notBookedCalls ) {
      setNotBookedCallsAlerts(mongoUser.customData.notifications.notBookedCalls);
    } else {
      setNotBookedCallsAlerts('none');
    }

  }, [mongoUser]);

  // get cached dashboard pie chart
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('simplecacheddashboardbreakdown')
      .count()
      .then( cachedCount => {
        setCachedDashboardPieChart(cachedCount);
      });

  }, [mongoUser]);

  // get cached dashboard piechart
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('simplecacheddashboardcounters')
      .count()
      .then( cachedCount => {
        setCachedDashboardCounters(cachedCount);
      });

  }, [mongoUser]);

  // get cached linecharts count
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('simplecachedlinecharts')
      .count()
      .then( cachedCount => {
        setCachedLineCharts(cachedCount);
      });

  }, [mongoUser]);

  // get cached heatmaps count
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('simplecachedheatmaps')
      .count()
      .then( cachedCount => {
        setCachedHeatMaps(cachedCount);
      });

  }, [mongoUser]);

  // get latest daily conversion
  // useEffect(() => {
  //   if ( mongoUser.customData.role !== 'admin' ) return;
  //
  //   mongoUser
  //     .mongoClient(mongoConfig.realmServiceName)
  //     .db(mongoConfig.databaseName)
  //     .collection('stats')
  //     .findOne(
  //       {function: 'computeDailyConversion' },
  //       {
  //         sort: { utcDateTime: -1 },
  //         projection: { utcDateTime: 1 }
  //       }
  //     )
  //     .then( latestConversionRecord => {
  //       let convHoursDiff = moment().diff(latestConversionRecord.utcDateTime, 'hours');
  //
  //       setLatestDailyConversion(latestConversionRecord);
  //       setLatestDailyConversionHoursDiff(convHoursDiff);
  //     });
  //
  // }, [mongoUser]);

  // get latest monthly conversion
  // useEffect(() => {
  //   if ( mongoUser.customData.role !== 'admin' ) return;
  //
  //   mongoUser
  //     .mongoClient(mongoConfig.realmServiceName)
  //     .db(mongoConfig.databaseName)
  //     .collection('stats')
  //     .findOne(
  //       {function: 'computeMonthlyConversion' },
  //       {
  //         sort: { utcDateTime: -1 },
  //         projection: { utcDateTime: 1 }
  //       }
  //     )
  //     .then( latestConversionRecord => {
  //       let convDaysDiff = moment().diff(latestConversionRecord.utcDateTime, 'days');
  //
  //       setLatestMonthlyConversion(latestConversionRecord);
  //       setLatestMonthlyConversionDaysDiff(convDaysDiff);
  //     });
  //
  // }, [mongoUser]);

  // get latest record from calls archive
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('callsArchive')
      .findOne(
        { },
        {
          sort: { utcDateTime: -1 },
          projection: { utcDateTime: 1 }
        }
      )
      .then( latestCallArchived => {
        let archiveDaysDiff = moment(latestCallArchived.utcDateTime).diff(moment(), 'days');

        setLatestCallsArchiveRecord(latestCallArchived);
        setLatestCallsArchiveRecordDaysDiff(archiveDaysDiff);
      });

  }, [mongoUser]);

  // get first record from calls
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('calls')
      .findOne(
        { },
        {
          sort: { utcDateTime: 1 },
          projection: { utcDateTime: 1 }
        }
      )
      .then( earliestCall => {
        let callsDaysDiff = moment(earliestCall.utcDateTime).diff(moment(), 'days');

        setEarliestCallsRecord(earliestCall);
        setEarliestCallsRecordDaysDiff(callsDaysDiff);
      });

  }, [mongoUser]);

  // get latest classified call
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('calls')
      .findOne(
        { callTypeClassifyFinished: true },
        {
          sort: { utcDateTime: -1 },
          projection: { utcDateTime: 1 }
        }
      )
      .then( latestCall => {
        let diff = moment.duration( moment().diff(moment(latestCall.utcDateTime))).humanize();
        setLatestPreCallClassifiedDiff(diff);
      });

  }, [mongoUser]);

  // get latest alerts aggregation record
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('callTypesByDate')
      .findOne(
        { },
        {
          sort: { utcDateTime: -1 },
          projection: { utcDateTime: 1 }
        }
      )
      .then( latestRecord => {
        let latestRecordDaysDiff = moment(latestRecord.utcDateTime).diff(moment(), 'days');

        setLatestAlertsAggregation(latestRecord)
        setLatestAlertsAggregationDiff(latestRecordDaysDiff)
      });

  }, [mongoUser]);

  // get latest pre classified call
  useEffect(() => {
    if ( mongoUser.customData.role !== 'admin' ) return;

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('calls')
      .findOne(
        { callTypePreClassifyFinished: true },
        {
          sort: { utcDateTime: -1 },
          projection: { utcDateTime: 1 }
        }
      )
      .then( latestCall => {
        let diff = moment.duration( moment().diff(moment(latestCall.utcDateTime))).humanize();
        setLatestCallClassifiedDiff(diff);
      });

  }, [mongoUser]);

  const handleMissedCallsAlertsChanged = (event) => {

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('usersdata')
      .updateOne(
        { userID: mongoUser.id, role: 'officeadmin'},
        { $set: {
          notifications: {
            notBookedCalls: notBookedCallsAlerts,
            missedCalls: event.target.value
          }
        }}
      ).then(() => {
        setMissedCallsAlerts(event.target.value);
        dispatch(doRefreshCustomData());
      }).catch((err) => {
        console.log('Notifications update error: ' + err);
      });

  }

  const handleNotBookedCallsAlertsChanged = (event) => {

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('usersdata')
      .updateOne(
        { userID: mongoUser.id, role: 'officeadmin'},
        { $set: {
          notifications: {
            missedCalls: missedCallsAlerts,
            notBookedCalls: event.target.value
          }
        }}
      ).then(() => {
        setNotBookedCallsAlerts(event.target.value);
        dispatch(doRefreshCustomData());
      }).catch((err) => {
        console.log('Notifications update error: ' + err);
      });

  }
  // customData.notifications.missedCalls

  return (
    <Box width={1} className={classes.settingsContainer}>
      <PageTitle thepage="/account/settings" />
      <br/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardContent className={classes.formContainer}>
              <Alert severity="info">Disclaimer: A.I. prediction scores are 97% accurate, based on similarity of patient calls to the dataset.</Alert>
            </CardContent>
          </Card>
          <br/>

          { (mongoUser.customData.role === 'officeadmin' && (missedCallsAlerts !== null || notBookedCallsAlerts !== null) ) &&
            <Card>
              <CardHeader subheader="Notifications"/>

              { (missedCallsAlerts !== null) &&
                <CardContent className={classes.formContainer}>
                  <FormControl >
                    <InputLabel id="missed-calls-email-alerts-label">Missed Calls Email Alerts</InputLabel>
                    <Select
                      labelId="missed-calls-email-alerts-label"
                      id="missed-calls-email-alerts"
                      value={missedCallsAlerts}
                      label="Missed Calls Email Alerts"
                      sx={{ minWidth: '20em' }}
                      onChange={handleMissedCallsAlertsChanged}
                    >
                      <MenuItem value='none'>None</MenuItem>
                      <MenuItem value='daily'>Daily Summary</MenuItem>
                    </Select>
                  </FormControl>
                </CardContent>
              }
              { (notBookedCallsAlerts !== null) &&
                <CardContent className={classes.formContainer}>
                  <FormControl >
                    <InputLabel id="not-booked-calls-email-alerts-label">Not Booked Calls Email Alerts</InputLabel>
                    <Select
                      labelId="not-booked-calls-email-alerts-label"
                      id="not-booked-calls-email-alerts"
                      value={notBookedCallsAlerts}
                      label="Not Booked Calls Email Alerts"
                      sx={{ minWidth: '20em' }}
                      onChange={handleNotBookedCallsAlertsChanged}
                    >
                      <MenuItem value='none'>None</MenuItem>
                      <MenuItem value='daily'>Daily Summary</MenuItem>
                    </Select>
                  </FormControl>
                </CardContent>
              }
            </Card>
          }

          { (mongoUser.customData.role === 'admin') &&
            <Card>
              <CardHeader
                subheader="Ring Staff Tools"
                action={
                  <Typography variant="caption" align="center" color="secondary">
                    v {theApp.ver} &nbsp;
                  </Typography>
                }/>
              <CardContent className={classes.formContainer}>
                <Box>
                  <Typography variant="subtitle2">Calls Classifying</Typography>

                  { (latestCallClassifiedDiff !== null) &&
                    <Typography>Latest call classified&nbsp;<b>{latestCallClassifiedDiff} ago</b></Typography>
                  }
                  { (latestCallPreClassifiedDiff !== null) &&
                    <Typography>Latest call pre-classified&nbsp;<b>{latestCallPreClassifiedDiff} ago</b></Typography>
                  }
                </Box>
                <br/>
                <Box>
                  <Typography variant="subtitle2">Cached Data</Typography>

                  { (cachedLineCharts !== null) &&
                    <Typography>Cached line charts:&nbsp;<b>{cachedLineCharts}</b></Typography>
                  }
                  { (cachedHeatMaps !== null) &&
                    <Typography>Cached heat maps:&nbsp;<b>{cachedHeatMaps}</b></Typography>
                  }
                  { (cachedDashboardCounters !== null) &&
                    <Typography>Cached dashboard counters:&nbsp;<b>{cachedDashboardCounters}</b></Typography>
                  }
                  { (cachedDashboardPieChart !== null) &&
                    <Typography>Cached dashboard pie charts:&nbsp;<b>{cachedDashboardPieChart}</b></Typography>
                  }

                </Box>
                <br/>
                <Box>
                  <Typography variant="subtitle2">Alerts Reports Aggregation</Typography>
                  { (latestAlertsAggregation === null ) &&
                    <Typography >Alerts aggregation data is loading...</Typography>
                  }
                  { (latestAlertsAggregation !== null) &&
                    <Stack direction="row" alignItems="center" gap={1}>
                      { (latestAlertsAggregationDiff <= -2 )
                        ? ( <CancelIcon fontSize="small" color="error"/> )
                        : ( <CheckCircleIcon fontSize="small" color="success"/> )
                      }
                      <Typography>Latest Alerts pre aggregation:&nbsp;<b><UserDateTime formatStr="ddd, MMM D, h:mm A" dateObj={latestAlertsAggregation.utcDateTime}/></b></Typography>
                    </Stack>
                  }

                </Box>
                <br/>
                <Box>
                  <Typography variant="subtitle2">Calls Archiving</Typography>
                  { (latestCallsArchiveRecord === null && earliestCallsRecord === null ) &&
                    <Typography >Calls Archive data is loading...</Typography>
                  }
                  { (latestCallsArchiveRecord !== null) &&
                    <Stack direction="row" alignItems="center" gap={1}>
                      { (latestCallsArchiveRecordDaysDiff < -90 )
                        ? ( <CancelIcon fontSize="small" color="error"/> )
                        : ( <CheckCircleIcon fontSize="small" color="success"/> )
                      }
                      <Typography>Latest call moved to archive:&nbsp;<b><UserDateTime formatStr="ddd, MMM D, h:mm A" dateObj={latestCallsArchiveRecord.utcDateTime}/></b></Typography>
                    </Stack>
                  }

                  { (earliestCallsRecord !== null) &&
                    <Stack direction="row" alignItems="center" gap={1}>
                      { (earliestCallsRecordDaysDiff < -90 )
                        ? ( <CancelIcon fontSize="small" color="error"/> )
                        : ( <CheckCircleIcon fontSize="small" color="success"/> )
                      }
                      <Typography>Earliest call in working collection:&nbsp;<b><UserDateTime formatStr="ddd, MMM D, h:mm A" dateObj={earliestCallsRecord.utcDateTime}/></b></Typography>
                    </Stack>
                  }

                </Box>
              </CardContent>
            </Card>
          }

        </Grid>
      </Grid>
    </Box>
  );
};
