import React, { useEffect, memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import RoleRouter from './RoleRouter';

import { doVerifyAuth } from "../utils";

import PageRoot from '../pages/PageRoot';
import { pagesConstants as pc } from '../constants';

const AppRouter = () => {
  const dispatch = useDispatch();
  const mongoUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(doVerifyAuth(mongoUser));
  }, [mongoUser, dispatch]);

  return (
    <Routes>
      <Route index element={
        <RoleRouter roles={null}>
          <PageRoot page={pc.PAGE_LOGIN} />
        </RoleRouter>
      } />

      <Route path="emailedinvitation">
        <Route path=":invitationid" element={
          <PageRoot page={pc.PAGE_EMAILED_INVITATION} />
        }/>
      </Route>

      <Route path="emailconfirm" element={
        <PageRoot page={pc.PAGE_CONFIRM_EMAIL} />
      }/>

      <Route path="azureauth" element={
        <PageRoot page={pc.PAGE_AZURE_AUTH} />
      }/>

      <Route path="googleauth" element={
        <PageRoot page={pc.PAGE_GOOGLE_AUTH} />
      }/>

      <Route path="login" element={
        <RoleRouter roles={null}>
          <PageRoot page={pc.PAGE_LOGIN} />
        </RoleRouter>
      }/>

      <Route path="dashboard" element={
        <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
          <PageRoot page={pc.PAGE_DASHBOARD} />
        </RoleRouter>
      }>
        <Route path="office">
          <Route path=":officeid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_DASHBOARD} />
            </RoleRouter>
          }/>
        </Route>
      </Route>

      {/* Calls Start */}
      <Route path="calls">
        {/* All Calls Start */}
        <Route path="all" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_ALL} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_ALL} />
            </RoleRouter>
          }>
            <Route path=":keywordphrase" element={
              <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
                <PageRoot page={pc.PAGE_CALLS_ALL} />
              </RoleRouter>
            }/>
          </Route>
        </Route>

        {/* Answered Calls Start */}
        <Route path="answered" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_ANSWERED} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_ANSWERED} />
            </RoleRouter>
          }>
            <Route path=":keywordphrase" element={
              <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
                <PageRoot page={pc.PAGE_CALLS_ANSWERED} />
              </RoleRouter>
            }/>
          </Route>
        </Route>

        {/* Booked Calls Start */}
        <Route path="booked" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_BOOKED} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_BOOKED} />
            </RoleRouter>
          }>
            <Route path=":keywordphrase" element={
              <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
                <PageRoot page={pc.PAGE_CALLS_BOOKED} />
              </RoleRouter>
            }/>
          </Route>
        </Route>

        {/* Not Booked Calls Start */}
        <Route path="notbooked" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_NOTBOOKED} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_NOTBOOKED} />
            </RoleRouter>
          }>
            <Route path=":keywordphrase" element={
              <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
                <PageRoot page={pc.PAGE_CALLS_NOTBOOKED} />
              </RoleRouter>
            }/>
          </Route>
        </Route>

        {/* Other Calls Start */}
        <Route path="other" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_OTHER} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_OTHER} />
            </RoleRouter>
          }>
            <Route path=":keywordphrase" element={
              <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
                <PageRoot page={pc.PAGE_CALLS_OTHER} />
              </RoleRouter>
            }/>
          </Route>
        </Route>

        {/* Missed Calls Start */}
        <Route path="missed" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_MISSED} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_MISSED} />
            </RoleRouter>
          }/>
        </Route>

        {/* Busy Calls Start */}
        <Route path="busy" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_BUSY} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_BUSY} />
            </RoleRouter>
          }/>
        </Route>

        {/* No answer Calls Start */}
        <Route path="noanswer" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_NOANSWER} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_NOANSWER} />
            </RoleRouter>
          }/>
        </Route>

        {/* IS Spam Calls Start */}
        <Route path="isspam" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_ISSPAM} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_ISSPAM} />
            </RoleRouter>
          }/>
        </Route>

        {/* Hangup Calls Start */}
        <Route path="hangup" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_HANGUP} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_HANGUP} />
            </RoleRouter>
          }/>
        </Route>

        {/* Voicemail Calls Start */}
        <Route path="voicemail" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_VOICEMAIL} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_VOICEMAIL} />
            </RoleRouter>
          }/>
        </Route>

        {/* Unknown Calls Start */}
        <Route path="unknown" element={
          <RoleRouter roles={['admin']}>
            <PageRoot page={pc.PAGE_CALLS_UNKNOWN} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin']}>
              <PageRoot page={pc.PAGE_CALLS_UNKNOWN} />
            </RoleRouter>
          }/>
        </Route>

        {/* Tagged Calls Start */}
        <Route path="tagged" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_CALLS_TAGGED} />
          </RoleRouter>
        }>
          <Route path=":callid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_CALLS_TAGGED} />
            </RoleRouter>
          }/>
        </Route>

        {/* Call Review Start */}
        <Route path="review" element={
          <RoleRouter roles={['callreviewer']}>
            <PageRoot page={pc.PAGE_REVIEWCALL} />
          </RoleRouter>
        }/>
      </Route>
      {/* Calls End */}

      {/* Manage Start */}
      <Route path="manage">
        <Route path="users" element={
          <RoleRouter roles={['admin']}>
            <PageRoot page={pc.PAGE_USERS} />
          </RoleRouter>
        }/>
        <Route path="groups" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin']}>
            <PageRoot page={pc.PAGE_GROUPS} />
          </RoleRouter>
        }/>
        <Route path="offices" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
            <PageRoot page={pc.PAGE_OFFICES} />
          </RoleRouter>
        }>
          <Route path=":officeid" element={
            <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin']}>
              <PageRoot page={pc.PAGE_OFFICES} />
            </RoleRouter>
          }/>
        </Route>
      </Route>
      {/* Manage End */}

      {/* Reports Start */}
      <Route path="reports">
        <Route path="callviews" element={
          <RoleRouter roles={['admin', 'companyadmin']}>
            <PageRoot page={pc.PAGE_CALLVIEWS} />
          </RoleRouter>
        }/>
      </Route>
      {/* Reports End */}

      <Route path="data" element={
        <RoleRouter roles={['admin', 'companyadmin', 'groupadmin']}>
          <PageRoot page={pc.PAGE_ALERTSALLDATA} />
        </RoleRouter>
      }/>

      {/* Account Start */}
      <Route path="account">
        <Route path="settings" element={
          <RoleRouter roles={['admin', 'companyadmin', 'groupadmin', 'officeadmin', 'callreviewer']}>
            <PageRoot page={pc.PAGE_ACCOUNTSETTINGS} />
          </RoleRouter>
        }/>
      </Route>
      {/* Account End */}

      <Route path="*" element={<PageRoot page={pc.PAGE_NOT_FOUND} />} />
    </Routes>
  );
};

export default memo(AppRouter);
