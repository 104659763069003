import React from 'react';
import { Box, Container } from '@mui/material';

// import SettingsIcon from '@mui/icons-material/Settings';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  "@keyframes rotationAnimation" : {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    }
  },
  refreshContainer: {
    height: '100vh',
    '& img': {
      opacity: 0.5,
      width: '100%',
      maxWidth: '200px',
      height: 'auto%',
      transformOrigin: "100% 100%",
      animation: `$rotationAnimation 0.5s infinite linear`,
      marginLeft: '50px',
      marginTop: '-150px',
    },
    '& .MuiSvgIcon-root': {
      opacity: 0.1,
      width: '100%',
      minHeight: '10em',
      height: '20%',
      animation: `$rotationAnimation 15s infinite linear`,
    }
  },
}));


export const InRefreshPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.refreshContainer} maxWidth="xs">
      <Box display="flex" height={1} >
        <Box width={1} m="auto">
          <img className={classes.appBarLogo} src="/img/r-logo-ringai.svg" alt="Loading" title="Loading" />
        </Box>
      </Box>
    </Container>
  );
}
