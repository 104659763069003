import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from './routers/AppRouter';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import theme from './config/theme';
import configureAppStore from './store';

const { store, persistor }  = configureAppStore();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRouter />
          </ThemeProvider>
        </StyledEngineProvider>
        </BrowserRouter>
      </PersistGate>
  </Provider>
);
