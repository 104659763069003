import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Grid, TextField, Button, Typography } from '@mui/material';

import { doCreateUser } from "../../utils";
import mongoConfig from "../../mongo";
import * as Realm from "realm-web";

export const ConfirmInvitationForm = (props) => {
  const dispatch = useDispatch();
  const { invitationData } = props;

  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [displayName, setDisplayName] = useState(invitationData.displayName || '');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);

  const handlePasswordChange = ({ target }) => {
    setNewPassword(target.value);
  };

  const handleDisplayNameChange = ({ target }) => {
    setDisplayName(target.value);
  };

  const handleCreateAccountSubmit = async (target) => {
    target.preventDefault();
    setSubmitInProgress(true);
    setError(null);

    if (!invitationData || !invitationData.username || displayName === '' || newPassword === '') {
      setError('All fields are required.');
      setSubmitInProgress(false);
      return;
    }

    try {
      const app = new Realm.App({ id: mongoConfig.realmAppId });
      const credentials = Realm.Credentials.anonymous();
      const user = await app.logIn(credentials);

      const userDataRecord = {
        status: 'pending',
        timeZone: 'US/Central',
        displayName: displayName,
        role: invitationData.role,
        company: invitationData.company,
        username: invitationData.username,
      };

      if (invitationData.groupsAccess && invitationData.groupsAccess.length > 0) {
        userDataRecord['groupsAccess'] = invitationData.groupsAccess;
      }

      if (invitationData.officesAccess && invitationData.officesAccess.length > 0) {
        userDataRecord['officesAccess'] = invitationData.officesAccess;
      }

      await user.mongoClient(mongoConfig.realmServiceName)
          .db(mongoConfig.databaseName)
          .collection('usersdata')
          .insertOne(userDataRecord);

      await dispatch(doCreateUser({ email: userDataRecord.username, password: newPassword }));
      setUserCreated(true);
    } catch (err) {
      console.error('Create User Error: ', err);
      setError('Failed to create user. Please try again.');
    } finally {
      setSubmitInProgress(false);
    }
  };

  return (
      <form method="POST" onSubmit={handleCreateAccountSubmit}>
        {!userCreated && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography>You are invited to join the {invitationData.company.toUpperCase()} team on Ring Analytics</Typography>
                    <Typography>Please confirm your Full Name, enter the Password, and then create your new account.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField value={invitationData.username} disabled required fullWidth label="Email" name="username" size="small" variant="outlined" slotProps={{
                      input: { readOnly: true, }
                    }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField error={!displayName} onChange={handleDisplayNameChange} value={displayName} required fullWidth label="Full Name" name="displayName" size="small" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField error={!newPassword} onChange={handlePasswordChange} required fullWidth label="Your Password" name="password" size="small" type="password" variant="outlined" />
                  </Grid>
                </Grid>
              </Grid>
              {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
              )}
              <Grid item xs={12}>
                <Button
                    disabled={!displayName || !newPassword || submitInProgress}
                    color="secondary" fullWidth type="submit" variant="contained"
                >
                  {submitInProgress ? 'Creating User. Please wait' : 'Confirm and Create Account'}
                </Button>
              </Grid>
              <Grid item justifyContent="center" alignContent="center" alignItems="center" container xs={12}>
                <NavLink to="/login">
                  I already have an account, Go to Login Screen
                </NavLink>
              </Grid>
            </Grid>
        )}
        {userCreated && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography>Please check your email to complete registration</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        )}
      </form>
  );
};