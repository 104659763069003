import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import {AppBar, Toolbar, Typography, Tooltip, Box, IconButton, Button, Menu, MenuItem} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import HelpIcon from '@mui/icons-material/Help';

import makeStyles from '@mui/styles/makeStyles';

import { doLogoutUser } from "../../utils";

const useStyles = makeStyles(() => ({
  topBar: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    height: 'fit-content',
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    height: '4em',
    lineHeight: '4em',
    minHeight: 'auto',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    justifyContent: 'space-between',
    '& h1': {
      fontSize: '1.2em',
      lineHeight: '2em',
      marginRight: '0.5em'
    }

  },
  dropMenu: {
    '& ul': {
      '& li': {
        fontSize: 'smaller'
      },
      '& a': {
        fontSize: 'smaller'
      },
      '& svg': {
        marginRight: '0.5em',
        fontSize: 'smaller'
      }
    }
  }
}));



const TopBar = ({pageTitle, helpText, hideHelpButton}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const accountMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mongoUser = useSelector( (state) => state.auth.user );

  const handleLogout = () => {
    dispatch( doLogoutUser() );
  };

  return (
    <AppBar position="static" component="div" elevation={1} className={classes.topBar}>
      <Toolbar className={classes.toolBar} >
        <Box sx={{display: 'flex'}}>
          { pageTitle !== null &&
            <Typography variant="h1">{pageTitle}</Typography>
          }
          { (helpText !== null && !hideHelpButton) &&
            <Tooltip
              className="titleTooltip"
              placement="right"
              title={<Typography fontSize={14}>{helpText}</Typography>}>
                <IconButton size="small">
                  <HelpIcon />
                </IconButton>
            </Tooltip>
          }
        </Box>

        <Button
          id="user-profile-menu-button"
          aria-controls={accountMenuOpen ? 'user-profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={accountMenuOpen ? 'true' : undefined}
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {mongoUser.customData.displayName}
        </Button>
        <Menu
          className={classes.dropMenu}
          id="user-profile-menu"
          MenuListProps={{
            'aria-labelledby': 'user-profile-menu-button',
          }}
          anchorEl={anchorEl}
          open={accountMenuOpen}
          onClose={handleClose}
        >
          <MenuItem component={NavLink} to="/account/settings" disableRipple>
            <SettingsIcon />
            Account Settings
          </MenuItem>
          <MenuItem onClick={handleLogout} disableRipple>
            <ExitToAppIcon />
            Exit App
          </MenuItem>
        </Menu>

      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
