import React, { Suspense }  from 'react';
import { Box } from '@mui/material';

// import CallsPageTemplate from '../../components/Calls/CallsPageTemplate';
const CallsPageTemplate = React.lazy(() => import('../../components/Calls/CallsPageTemplate'));
import PageTitle from '../../components/Common/PageTitle';

export const BusyCallsPage = (props) => {

  return (
    <Box>
      <PageTitle thepage="/calls/busy" />
      <Suspense fallback={<span>...</span>}>
        <CallsPageTemplate
          props={props}
          callsSection='busy'
          lineChartsHeading='Busy Signals'
          heatmapColors='oranges'
        />
      </Suspense>
    </Box>
  );
};
